import React, { FC, memo, useMemo } from 'react'
import { HeadlineProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { SizeTypes } from '@/types/size'
import { useTeamContext } from '@/context/TeamContext'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'

type Props = {
    component: Pick<HeadlineProps, 'text'>
    noStyle?: boolean
    testID?: string
}

const Headline: FC<Props> = ({ component, testID }) => {
    const { team } = useTeamContext()
    const { currentDevice } = useMediaQueryContext()
    const { text } = component

    const headline = useMemo((): string => {
        let result = text
        if (text.includes('{teamName}')) {
            result =
                currentDevice === SizeTypes.MOBILE
                    ? text.replace('{teamName}', team.shortName ?? '')
                    : text.replace('{teamName}', team.name ?? '')
        }

        return result
    }, [currentDevice, team?.name, team?.shortName, text])

    return (
        <NonFlexingContainer
            testID={testID}
            marginLeft={['spacing-6', 'spacing-6', 'spacing-9', 'spacing-none']}
            marginBottom="spacing-6"
        >
            <CardHeadline
                testID={`${testID}-text`}
                text={headline}
                cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
            />
        </NonFlexingContainer>
    )
}

export default memo(Headline)
