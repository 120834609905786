import React, { FC, useEffect, useMemo, useState } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import lightSurfaceColors from '@sport1/news-styleguide/lightSurfaceColors'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { useRouter } from 'next/router'
import { isPureActive } from '../ThirdParty/Piano/util'
import { CUSTOM_VENDOR_IDS } from '@/utils/consent/consentVendors'
import { useConsentManager } from '@/utils/consent/ConsentInfoProvider'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'

export type PinpollProps = {
    height?: number
    title?: string
    isArticle?: boolean
    hideUnfilled?: boolean
}

const Pinpoll: FC<PinpollProps> = ({ title = 'DEINE MEINUNG', isArticle, hideUnfilled }) => {
    const { consentInfo, getConsentById } = useConsentManager()
    const [isSurvey, setIsSurvey] = useState(true)
    const { asPath } = useRouter()
    const dataUrl = asPath === '/' ? 'https://www.sport1.de' : `https://www.sport1.de${asPath}`
    const { adKeys = [], adTargeting = {} } = useAdPlacementContext()

    useEffect(() => {
        const shouldCheck =
            !isPureActive() &&
            consentInfo.consent_info !== 'off' &&
            getConsentById('custom', CUSTOM_VENDOR_IDS.PINPOLL) &&
            hideUnfilled
        if (shouldCheck) {
            function checkPinpollFilled(callback: Function) {
                const i = setInterval(() => {
                    if (
                        document.querySelector('[data-mode=aipoll].loaded') &&
                        window?.Pinpoll?.embed?.embeds
                    ) {
                        clearInterval(i)
                        callback(window.Pinpoll.embed.embeds.length > 0)
                    }
                }, 1000)
            }
            checkPinpollFilled((a: boolean) => {
                setIsSurvey(a)
            })
        }
    }, [consentInfo.consent_info, getConsentById, setIsSurvey, hideUnfilled])

    const pinpollTopic = useMemo(() => {
        if (asPath === '/') {
            return 'home'
        }
        const { channel, subChannel } = adTargeting
        if (channel || (channel && subChannel)) {
            return [channel, subChannel].filter(v => v).join(' ')
        } else if (adKeys?.length > 0) {
            return adKeys[0]
        }
        return ''
    }, [adKeys, asPath, adTargeting])

    if (isPureActive()) return null

    if (consentInfo.consent_info === 'off') {
        return <NonFlexingContainer className="pinpoll-fallback" marginBottom="spacing-9" />
    }

    return isSurvey && getConsentById('custom', CUSTOM_VENDOR_IDS.PINPOLL) ? (
        <NonFlexingContainer
            className="px-3 bg-none sm:px-6 md:px-3 md:bg-white lg:px-0"
            {...(isArticle
                ? {
                      marginBottom: ['spacing-9', 'spacing-9', 'spacing-11'],
                      paddingX: ['spacing-none', 'spacing-none', 'spacing-11'],
                  }
                : {
                      paddingX: ['spacing-6', 'spacing-6', 'spacing-6', 'spacing-11'],
                      paddingTop: ['spacing-none', 'spacing-none', 'spacing-none', 'spacing-11'],
                      marginBottom: 'spacing-11',
                  })}
        >
            <NonFlexingContainer
                {...(isArticle
                    ? {
                          padding: 'spacing-9',
                          backgroundColor: lightSurfaceColors['background-tertiary'],
                      }
                    : {})}
            >
                <NonFlexingContainer marginBottom="spacing-6">
                    <CardHeadline
                        {...(!isArticle
                            ? {
                                  cardHeadlineType: [
                                      'cardHeadlineS',
                                      'cardHeadlineM',
                                      'cardHeadlineM',
                                  ],
                              }
                            : {
                                  cardHeadlineType: 'cardHeadlineXS',
                              })}
                        text={title || ''}
                        testID="pinpoll-headline"
                    />
                </NonFlexingContainer>
                <NonFlexingContainer minHeight={250}>
                    <div
                        data-url={dataUrl}
                        className="pinpoll"
                        data-mode="aipoll"
                        data-autoplay="6"
                        data-topic={pinpollTopic}
                    />
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    ) : null
}

export default Pinpoll
